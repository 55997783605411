export default {
	keys:['zh-Hans','en','es'],//中文 英文 西班牙语
    lang:{
        //分组
        nav:{
            _translate:[
               ['首页','Home','Home']
            ],
            key:['健值','key','key'] 
        },
        index:{
            _translate:[
               ['示例','demo1','demo1']
            ],
            key:['健值','key','key'] 
        }
    },
    //全局
    translate:[
       // ['兜底','mydod','test']
    ]
}